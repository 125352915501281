<template>
  <div class="container text-center mt-5">
    <form @submit.prevent="submit()" ref="formRef">
      <div class="mb-3">
        <label for="formFile" class="form-label">Report</label>
        <input
          class="form-control"
          type="file"
          ref="file1"
          name="file1"
          v-on:change="onFileChanged1()"
          id="formFile"
        />
      </div>
      <p>{{ resp }}</p>
    </form>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  setup() {
    const resp = ref("");
    const file1 = ref(null);
    const formRef = ref(null);

    onMounted(() => {
      //here
    });

    const onFileChanged1 = async () => {
      const file = file1.value.files[0];
      try {
        const res = await postFileApi(file);
        resp.value = res.data.data;
        //
      } catch (error) {
        console.log(error);
      }
      //const pathphoto = URL.createObjectURL(file);
    };

    const submit = () => {
      //here
    };

    const postFileApi = async (file1) => {
      const formData = new FormData();
      if (file1) {
        formData.append("file", file1);
      }
      const url = "https://reportexcel.dev.3dboosterstudio.com/api/reportexcel";
      const data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.data;
          link.download = "report.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return data;
    };
    return {
      formRef,
      submit,
      onFileChanged1,
      file1,
      resp,
    };
  },
};
</script>
